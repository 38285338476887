.footer-container {
  position: relative;
}

.footer-container > hr {
  border: 1px solid var(--lightgray);
}
.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 15rem;
}

.social-links {
  display: flex;
  gap: 4rem;
}
.social-links > img {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
.logo-f > img {
  width: 15rem;
}

.blur-f1 {
  width: 26rem;
  height: 12rem;
  right: 15%;
  bottom: 0;
  filter: blur(185px);
  background: maroon;
}

.blur-f2 {
  width: 26rem;
  height: 12rem;
  left: 15%;
  bottom: 0;
  filter: blur(185px);
  background: maroon;
}
